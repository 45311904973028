@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/base-variables';
@import '~src/styles/mixins/supernova-variables';

.wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    background: @magritte-color-base-white;

    @media @screen-magritte-gt-s {
        background: no-repeat fixed center center;
        background-size: contain;
    }
}

.wrapper-applicant {
    @media @screen-magritte-gt-s {
        background-image: url('assets/applicant-auth-bg.jpg');
    }
}

.wrapper-employer {
    @media @screen-magritte-gt-s {
        background-image: url('assets/employer-auth-bg.jpg');
    }
}

.wrapper_with-header {
    min-height: calc(100vh - @supernova-navi-height-lvl-2-xs-redesign);

    @media @screen-magritte-gt-xs {
        min-height: calc(100vh - @supernova-navi-height-lvl-full);
    }
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    min-height: 100%;

    padding-top: @magritte-core-space-5-x;
    padding-right: @magritte-core-space-4-x;
    padding-left: @magritte-core-space-4-x;

    @media @screen-magritte-gt-xs {
        padding-top: @magritte-core-space-20-x;
        padding-right: 0;
        padding-left: 0;
    }
}

.copyright {
    padding-top: @magritte-core-space-6-x;
    padding-bottom: @magritte-core-space-2-x;

    @media @screen-magritte-gt-xs {
        padding-bottom: @magritte-core-space-6-x;
    }
}
