@import '@hh.ru/magritte-ui/base-variables';

.link {
    width: @magritte-core-size-10-x;
    height: @magritte-core-size-10-x;
}

.logo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
