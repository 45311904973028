.messengers {
    display: flex;
    gap: 12px;
}

.messenger {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
}
