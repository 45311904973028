.icon {
    display: inline-block;

    + .icon {
        margin-left: 4px;
    }
}

.text {
    text-align: center;
}
